.app {
  max-width: 800px;
  max-height: 800px;
  margin: 0 auto;
  padding: 10px;
  overflow: hidden;
}

/* 최고가 입력 폼 스타일 */
.max-price-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: space-between;
}

.site-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.max-price-container label {
  margin-right: 10px;
}

.max-price-input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.instant-bid-btn {
  margin-right: 10px;
  padding: 10px 13px;
  background-color: #ff4d4f;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.instant-bid-btn:hover {
  background-color: #ff7875;
}

/* 입찰 입력 폼 스타일 */
.bid-inputs-container {
  margin-bottom: 20px;
}

.bid-inputs {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.bid-inputs input {
  flex: 1;
  margin-right: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.place-bid-btn {
  padding: 10px 10px;
  background-color: #1890ff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
}

.place-bid-btn2 {
  padding: 10px 10px;
  background-color: #52c41a;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.place-bid-btn3 {
  padding: 10px 10px;
  background-color: #ff4d4f;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.place-bid-btn5 {
  padding: 10px 10px;
  background-color: #696969;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.place-bid-btn:hover {
  background-color: #40a9ff;
}

/* 입찰 내역 테이블 스타일 */
.bid-history-container {
  text-align: center;
}

.bid-history-table {
  max-height: 200px;
  overflow-y: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
  font-size: 0.7rem;
}

table th,
table td {
  padding: 10px;
  border-bottom: 1px solid #ccc;
  text-align: center;
}

table th {
  background-color: #f0f0f0;
}

div {
  display: block;
  unicode-bidi: isolate;
}

div.scrollybase {
  background: #FFF;
  background-image: none;
  scrollbar-face-color: #EEEEEE;
  scrollbar-highlight-color: #969696;
  scrollbar-3dlight-color: #FFFFFF;
  scrollbar-darkshadow-color: #FFFFFF;
  scrollbar-shadow-color: #969696;
  scrollbar-arrow-color: #969696;
  scrollbar-track-color: #EEEEEE;
}

div.bidscroll {
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  display: block;
  unicode-bidi: isolate;
}

div.scrollybase {
  background: #FFF;
  background-image: none;
  scrollbar-face-color: #EEEEEE;
  scrollbar-highlight-color: #969696;
  scrollbar-3dlight-color: #FFFFFF;
  scrollbar-darkshadow-color: #FFFFFF;
  scrollbar-shadow-color: #969696;
  scrollbar-arrow-color: #969696;
  scrollbar-track-color: #EEEEEE;
}

.bid_tbl2 {
  width: 100%;
  border-top: solid 2px #cbcbcb;
  border-bottom: solid 2px #cbcbcb;
  color: #737373;
  font-size: 11px;
}

tbody {
  display: table-row-group;
  vertical-align: middle;
  unicode-bidi: isolate;
  border-color: inherit;
}

.mtxxs {
  margin-top: 5px;
}

.mtxxs {
  margin-top: 5px;
}

/* CSS 파일 또는 style 태그에 추가 */
.checkbox, .checkbox0, .checkbox1, 
.checkbox2, .checkbox3, .checkbox4 {
  appearance: none;
  /* 기본 체크 박스 스타일 제거 */
  -webkit-appearance: none;
  /* Safari 지원 */
  -moz-appearance: none;
  /* Firefox 지원 */
  width: 20px;
  /* 체크 박스 너비 조정 */
  height: 20px;
  /* 체크 박스 높이 조정 */
  border: 2px solid #acacac;
  /* 기본 테두리 스타일 */
  border-radius: 5px;
  /* 라운드 처리 */
  cursor: pointer;
  /* 마우스 커서를 포인터로 변경 */
}

/* 체크된 상태일 때의 스타일 */
.checkbox4 {
  background-color: #acacac;
  /* 붉은색 계열의 배경색 */
}
.checkbox1:checked {
  background-color: green;
  /* 붉은색 계열의 배경색 */
}
.checkbox2:checked {
  background-color: blue;
  /* 붉은색 계열의 배경색 */
}
.checkbox3:checked {
  background-color: red;
  /* 붉은색 계열의 배경색 */
}

/* Switch container */
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
}

/* Hide the default checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.switch label {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

/* The circle */
.switch label::before {
  position: absolute;
  content: "";
  height: 17px;
  width: 17px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

/* When the checkbox is checked, add a blue background */
.switch input:checked+label {
  background-color: #4CAF50;
}

/* Move the circle to the right when checked */
.switch input:checked+label::before {
  transform: translateX(17px);
}

/* Optional styles for a more polished look */
.switch input:focus+label {
  box-shadow: 0 0 1px #4CAF50;
}